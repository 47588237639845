<template>
    <div>
        <b-card v-for="(ticket, key) in form.tickets" :key="key" class="mb-3">
            <h5 class="mb-3">Ticket {{ key + 1 }}: {{ ticket.name }}</h5>
            <b-alert class="border" variant="light" v-if="key == 0" align-v="center" show>
                <span class="mr-2">
                    <font-awesome-icon icon="info-circle" class="mr-1"/>
                    Wil je bovenstaande gegevens overnemen?
                </span>
                <b-button @click="copyDetails(ticket)" size="xs" variant="outline-secondary mt-2 mt-md-0 align-baseline">
                    Ja, gegevens overnemen
                </b-button>
            </b-alert>
            <b-row>
                <b-col md="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Voornaam</label>
                        <b-form-input v-model="ticket.firstname" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                    </ValidationProvider>
                </b-col>
                <b-col md="6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Achternaam</label>
                        <b-form-input v-model="ticket.lastname" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <ValidationProvider rules="required|email" v-slot="{ errors }">
                <div class="mb-3">
                    <label class="required">E-mail</label>
                    <b-form-input v-model="ticket.email" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
            <ValidationProvider rules="required|phone" v-slot="{ errors }">
                <div class="mb-3">
                    <label class="required">Telefoonnummer</label>
                    <b-form-phone v-model="ticket.phone" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                    <label class="required">Geboortedatum</label>
                    <b-form-input v-model="ticket.birthdate" type="date" max="9999-12-31" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                    <label class="required">Geslacht</label>
                    <b-form-select v-model="ticket.gender" :options="genderSelect" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
        </b-card>
    </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import BFormPhone from '@/shared/components/BootstrapPhoneInput'

  export default {
    components: {
      ValidationProvider,
      BFormPhone,
    },
    props: {
        form: {
            type: [Object, String],
            default: null,
        },
    },
    data() {
      return {
        genderSelect: [
          { text: 'Maak een keuze', value: null },
          { text: 'Man', value: 'male' },
          { text: 'Vrouw', value: 'female' },
          { text: 'Anders', value: 'other' },
        ],
      }
    },
    computed: {},
    methods: {
        copyDetails: function(ticket) {
            ticket.gender = this.form.customer.gender;
            ticket.firstname = this.form.customer.firstname;
            ticket.lastname = this.form.customer.lastname;
            ticket.email = this.form.customer.email;
            ticket.phone = this.form.customer.phone;
            ticket.birthdate = this.form.customer.birthdate;
        },
    },
    mounted() {},
  }
</script>