<template>
  <div>
    <Header :title="event.name ? event.name : null" :lead="(event.location ? event.location.name : null) + (event.location ? ' - ' + event.location.place : null)" :header="event.header_image ? `https://api.tixgo.nl/files/` + event.header_image : null" />
    <b-container class="header-pull">
      <Stepper :step="step" :steps="steps" />
      <template v-if="step == 2">
        <ValidationObserver ref="form2" slim>
          <b-card no-body class="mb-3">
            <b-card-body>
              <h3>Persoonlijke Informatie</h3>
              <CustomerDetailsForm ref="CustomerDetailsForm" :form="form" />
              <template v-if="event.is_personal_ticket">
                <hr />
                <h3 class="mb-3">Ticket personalisatie</h3>
                <PersonalDetailsForm :form="form" />
              </template>
            </b-card-body>
            <b-card-footer>
              <b-row>
                <b-col>
                  <b-button variant="outline-secondary" @click="reset">
                    <font-awesome-icon icon="chevron-left" />
                    Terug
                  </b-button>
                </b-col>
                <b-col class="text-right">
                  <div v-if="time_left" class="reservation-timer bg-white text-center rounded border mr-2 align-middle">{{ time_left }}</div>
                  <b-button type="button" variant="primary" @click="nextStep" :disabled="isSubmited">
                    Volgende
                    <font-awesome-icon v-if="!isSubmited" icon="chevron-right" />
                    <b-spinner small v-if="isSubmited" class="align-middle" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </ValidationObserver>
      </template>

      <template v-if="step == 3">
        <ValidationObserver ref="form3" slim>
          <b-card no-body>
            <b-card-header>
              <b-row>
                <b-col class="font-weight-bold">Ticket</b-col>
                <b-col class="text-right font-weight-bold">Prijs</b-col>
                <b-col class="text-right font-weight-bold" v-if="event.is_customer_ticket_fee">Service Fee</b-col>
                <b-col class="text-right font-weight-bold">Aantal</b-col>
                <b-col class="text-right font-weight-bold">Totaal</b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <div>
                <b-row v-for="(ticket,key) in getCombineTickets" :key="key">
                  <b-col>{{ ticket.name }}</b-col>
                  <b-col class="text-right">{{ ticket.price|toCurrency }}</b-col>
                  <b-col class="text-right" v-if="event.is_customer_ticket_fee">{{ event.customer_ticket_fee|toCurrency }}</b-col>
                  <b-col class="text-right">{{ ticket.amount }}</b-col>
                  <b-col class="text-right">{{ calcTicketPriceWithAmount(ticket)|toCurrency }}</b-col>
                </b-row>
              </div>
              <hr />

              <template v-if="event.is_accept_donations">
                <b-row align-v="center">
                  <b-col md="10">
                    <h4 class="mb-0 fs-5">Donatie/vrije gift</h4>
                    <p class="text-muted mb-2 mb-md-0">{{ event.donation_description }}</p>
                  </b-col>
                  <b-col md="2">
                    <ValidationProvider rules="currency" v-slot="{ errors }">
                      <b-input-group prepend="€">
                        <b-form-input v-money="money" v-model="form.donation_amount" min="0" placeholder="0.00" />
                      </b-input-group>
                      <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <hr />
              </template>

              <template v-if="form.customer.phone">
                <div class="mb-3">
                  <b-row align-v="center">
                    <b-col cols="8">
                      <b-form-checkbox v-model="form.is_send_whatsapp">
                        Stuur mijn tickets ook via WhatsApp.
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="4" class="text-right">
                      <template v-if="form.is_send_whatsapp">
                        &euro; 0,25
                      </template>
                    </b-col>
                  </b-row>
                </div>
              </template>
              
              <ValidationProvider rules="required|oneOf:1" v-slot="{ errors }">
                <div class="mb-3">
                  <b-form-checkbox v-model="form.is_agree">
                    Ik ga akkoord met de algemene voorwaarden en het privacy statement.
                  </b-form-checkbox>
                  <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
              <hr />
              <b-row align-v="center">
                <b-col md="6">
                  <!--
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                      <label class="required">Betaalmethode</label>
                      <b-form-select v-model="form.payment_method" :options="paymentOptions" />
                      <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                  -->
                </b-col>
                <b-col md="6" class="text-right">
                  <div class="fs-5">Totaal: {{ calcTotalPrice|toCurrency }}</div>
                  <template v-if="event.is_customer_transaction_fee && calcTotalPrice > 0">
                    <p class="text-muted mb-0">Exclusief transactiekosten van minimaal &euro; 0,35</p>
                  </template>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <b-row>
                <b-col>
                  <b-button variant="outline-secondary" @click="prevStep">
                    <font-awesome-icon icon="chevron-left" />
                    Terug
                  </b-button>
                </b-col>
                <b-col class="text-right">
                  <div v-if="time_left" class="reservation-timer bg-white text-center rounded border mr-2 align-middle">{{ time_left }}</div>
                  <b-button type="button" variant="primary" @click="nextStep" :disabled="isSubmited">
                    Betalen
                    <font-awesome-icon v-if="!isSubmited" icon="chevron-right" />
                    <b-spinner small v-if="isSubmited" class="align-middle" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </ValidationObserver>
      </template>
    </b-container>
    <b-modal
      id="timeout-modal"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      centered
    >
      <template #modal-title>
        Sessie verlopen
      </template>
      <div class="text-center mb-3">
        Je huidige sessie om tickets te bestellen is verlopen.<br/>
        Klik op "opnieuw proberen" om nieuwe tickets te bestellen.
      </div>
      <b-button @click="reset" variant="primary" block>Opnieuw proberen</b-button>
    </b-modal>
  </div>
</template>
  
<script>
  import { VMoney } from 'v-money'
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Header from '@/shared/components/Header'
  import Stepper from '@/shared/components/Stepper'
  import CustomerDetailsForm from '@/shared/components/CustomerDetailsForm'
  import PersonalDetailsForm from '@/shared/components/PersonalDetailsForm'

  export default {
    metaInfo: {
      title: 'Afrekenen',
    },
    components: {
      Header,
      ValidationObserver,
      ValidationProvider,
      Stepper,
      CustomerDetailsForm,
      PersonalDetailsForm,
    },
    directives: {
      money: VMoney
    },
    data() {
      return {
        money: {
          decimal: '.',
          thousands: '',
          prefix: '',
          suffix: '',
          precision: 2,
        },
        step: 2,
        time_left: null,
        isSubmited: false,
        form: {
          client_id: null,
          client_secret: null,
          customer: {
            company_name: null,
            firstname: null,
            lastname: null,
            email: null,
            phone: null,
            birthdate: null,
            address: null,
            zipcode: null,
            city: null,
            gender: null,
          },
          expires_at: null,
          slot: null,
          tickets: [],
          donation_amount: null,
          payment_method: null,
        },
        steps: [
          { icon: 'fa-ticket', title: 'Tickets' },
          { icon: 'fa-user', title: 'Informatie' },
          { icon: 'fa-envelope', title: 'Afronden' },
        ],
        event: {
          slots: []
        },
        genderSelect: [
          { text: 'Maak een keuze', value: null },
          { text: 'Man', value: 'male' },
          { text: 'Vrouw', value: 'female' },
          { text: 'Anders', value: 'other' },
        ],
        paymentOptions: [
          { text: 'Maak een keuze', value: null },
          { text: 'iDEAL', value: 'ideal' },
        ],
        interval: null,
      }
    },
    computed: {
      getCombineTickets() {
        if (this.form.tickets.length) {
          let tickets = this.form.tickets.reduce((accum, val) => {
            let summary = accum.get(val.item) || Object.assign({}, val, {amount:0});
            summary.amount++;
            return accum.set(val.item, summary);
          }, new Map());

          return [...tickets.values()];
        }
        return [];
      },
      calcTotalPrice() {
        let count = 0;

        if (this.form.donation_amount) {
          let amount = this.form.donation_amount;
          //amount = amount.replace('.','').replace(',','.');
          count = count + parseFloat(amount);
        }

        this.form.tickets.forEach(ticket => {
          count = count + this.calcTicketPrice(ticket)
        });

        if (this.form.is_send_whatsapp) {
          count = count + 0.25;
        }

        return count;
      },
      calcTicketPrice() {
        return function(ticket) {
          return Number(ticket.price) + Number(this.event.customer_ticket_fee);
        }
      },
      calcTicketPriceWithAmount() {
        return function(ticket) {
          return (Number(ticket.price) + Number(this.event.customer_ticket_fee)) * Number(ticket.amount);
        }
      },
    },
    methods: {
      isLoggedIn: function() {
        if (this.$cookies.get('token')) {
            return true;
        }
        return false;
      },
      reset() {
        if (this.$route.params.host) {
          this.$router.push('/' + this.$route.params.host + '/event/' + this.$route.params.event + '/' + this.$route.params.slot);
        } else {
          this.$router.push('/event/' + this.$route.params.event + '/' + this.$route.params.slot);
        }
      },
      async nextStep() {

        this.isSubmited = true;

        let check = await this.$refs['form' + this.step].validate();

        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        
        if (!check) {
          this.isSubmited = false;
          return;
        }

        // Update reservation.
        this.updateReservation();

        if (this.step == 3) {
          return this.submitStep();
        }
        
        this.step++;
      },
      submitStep() {
        this.$axios.put('https://api.tixgo.nl/checkout/' + this.form.client_id, this.form)
        .then( response => {
            if (response.data) {
              // Dev
              if (location.hostname === "localhost") {
                window.open(response.data, '_blank');
              } else {
                window.location = response.data;
              }
            }
        }).catch((reason) => { 
          console.log(reason);
        });
      },
      prevStep: function() {

        // Update reservation.
        this.updateReservation();

        this.step--;
      },
      getEvent: function() {
        this.$axios.get('https://api.tixgo.nl/event/' + this.$route.params.event + '?slug=' + this.$route.params.slot)
          .then( response => {
              if (response.data) {
                this.event = response.data;
              }
          }
        ).catch((reason) => {
            console.log(reason);
            this.error = 'We konden de webshop niet vinden.';
        });
      },
      fetchReservation: function() {
        let data = {
          client_id: this.form.client_id,
          client_secret: this.form.client_secret,
        }

        // We are using a POST to receive the data, because GET is not secure enough.
        this.$axios.post('https://api.tixgo.nl/reservation/' + this.form.client_id, data)
        .then( response => {
            if (response.data.status == 'success') {
              this.form = response.data;
              this.form.is_update_customer = true;

              this.getEvent();
              this.$refs.CustomerDetailsForm.fetchCustomer();
            } else {
              this.$bvModal.show('timeout-modal');
            }
        }).catch((reason) => { 
          reason;
        });
      },
      updateReservation: function() {
        this.$axios.put('https://api.tixgo.nl/reservation/' + this.form.client_id, this.form)
        .then( response => {
            if (response.data) {
              this.form = response.data;
              this.isSubmited = false;
            }
        }).catch((reason) => { 
          console.log(reason);
          this.isSubmited = false;
        });
      },
      addZero: function(i) {
        if (i < 10) {i = "0" + i}
        return i;
      },
    },
    mounted() {
      this.form.client_id = localStorage.getItem('client_id');
      this.form.client_secret = localStorage.getItem('client_secret');

      // Session tokens are missing so show timeout.
      if (!this.form.client_id || !this.form.client_secret) {
        this.$bvModal.show('timeout-modal');
      } else {
        // Update reservation.
        this.fetchReservation();
      }

      this.interval = setInterval(() => {
        if (this.form.expires_at) {
          let _second = 1000;
          let _minute = _second * 60;
          let _hour = _minute * 60;

          let end = new Date(this.form.expires_at);
          let now = new Date();
          let distance = end - now;

          if (distance < 0) {
            this.time_left = '00:00';
            this.$bvModal.show('timeout-modal');
            return;
          }

          let minutes = Math.floor((distance % _hour) / _minute);
          let seconds = Math.floor((distance % _minute) / _second);

          this.time_left = this.addZero(minutes) + ':' + this.addZero(seconds);
        }
      },500);
    },
    destroyed() {
      clearInterval(this.interval)
    }
  }
</script>

<style scoped>
  .reservation-timer{
    display:inline-block;
    padding:7px;
  }
</style>