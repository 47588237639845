<template>
    <div>
        <template v-if="isSuccess">
            <div class="text-center">
                <font-awesome-icon class="text-primary mb-2" icon="check" size="4x" />
                <h1>Je bent nu ingelogd</h1>
                <p>Je bent nu succesvol ingelogd. Klik op 'Doorgaan' om verder te gaan.</p>
                <b-button @click="$bvModal.hide('login-modal')" variant="outline-primary">
                    Doorgaan
                </b-button>
            </div>    
        </template>

        <template v-if="isWaiting && !isSuccess">
            <div class="text-center">
                <font-awesome-icon class="text-primary mb-2" icon="envelope" size="4x" />
                <h1>Verificatie</h1>
                <b-alert v-if="error" variant="danger" show>
                    {{ error }}
                </b-alert>
                <p>Je ontvangt per e-mail een verificatie link om in te loggen op jouw account.</p>
                <p>Ga naar je e-mailinbox en volg de instructies. Geen e-mail ontvangen? Bekijk dan ook je spam folder.</p>
                <p>De link in de e-mail is 1 uur geldig.</p>
                <b-button variant="outline-primary" @click="error = null; isWaiting = false;">
                    Opnieuw proberen
                </b-button>
            </div>
        </template>

        <template v-if="!isWaiting">
            <div class="text-center">
                <h5>Ga verder zonder in te loggen</h5>
                <p class="text-muted">Je tickets zijn later beschikbaar in je account.</p>
                <b-button @click="$bvModal.hide('login-modal')" variant="primary" block>
                    Doorgaan als Gast
                </b-button>
            </div>
            <div class="divider text-muted fs-5 mb-3 mt-3">of</div>
            <div class="text-center">
                <h5>Al een Tixgo-account?</h5>
                <p class="text-muted">Log hier in voor een snellere afrekenervaring.</p>
            </div>
            <ValidationObserver ref="form1" slim>
                <b-form @submit.prevent="login()">
                    <b-alert v-if="error" variant="danger" show>
                        {{ error }}
                    </b-alert>
                    <ValidationProvider rules="required|email" v-slot="{ errors }">
                        <div class="mb-3">
                            <label class="mb-2 d-none">E-mailadres</label>
                            <b-form-input placeholder="E-mailadres" type="text" v-model="form.email" :class="errors.length > 0 ? 'is-invalid' : ''" />
                            <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                    </ValidationProvider>
                    <b-button type="button" @click="login()" variant="primary" block :disabled="isSubmited">
                        Inloggen
                        <b-spinner small v-if="isSubmited" class="align-middle" />
                    </b-button>
                </b-form>
            </ValidationObserver>
            
            <template v-if="hasMethods && true == false">
                <div class="divider text-muted fs-5 mb-3 mt-3">of</div>
                <b-row cols="1" cols-sm="2">
                    <b-col class="mb-2" v-if="hasMethod('linkedin')">
                        <b-button variant="linkedin" size="sm" block>
                            <font-awesome-icon icon="fa-brands fa-linkedin" class="mr-2" />
                            Aanmelden met LinkedIn
                        </b-button>
                    </b-col>
                    <b-col class="mb-2" v-if="hasMethod('microsoft')">
                        <b-button variant="microsoft" size="sm" block>
                            <font-awesome-icon icon="fa-brands fa-microsoft"  class="mr-2" />
                            Aanmelden met Microsoft
                        </b-button>
                    </b-col>
                    <b-col class="mb-2" v-if="hasMethod('google')">
                        <b-button variant="google" size="sm" block>
                            <font-awesome-icon icon="fa-brands fa-google" class="mr-2" />
                            Aanmelden met Google
                        </b-button>
                    </b-col>
                    <b-col class="mb-2" v-if="hasMethod('apple')">
                        <b-button variant="apple" size="sm" block>
                            <font-awesome-icon icon="fa-brands fa-apple" class="mr-2" />
                            Aanmelden met Apple
                        </b-button>
                    </b-col>
                    <b-col class="mb-2" v-if="hasMethod('facebook')">
                        <b-button variant="facebook" size="sm" block>
                            <font-awesome-icon icon="fa-brands fa-facebook" class="mr-2" />
                            Aanmelden met Facebook
                        </b-button>
                    </b-col>
                    <b-col class="mb-2" v-if="hasMethod('instagram')">
                        <b-button variant="instagram" size="sm" block>
                            <font-awesome-icon icon="fa-brands fa-instagram" class="mr-2" />
                            Aanmelden met Instagram
                        </b-button>
                    </b-col>
                    <b-col class="mb-2" v-if="hasMethod('twitter')">
                        <b-button variant="twitter" size="sm" block>
                            <font-awesome-icon icon="fa-brands fa-twitter" class="mr-2" />
                            Aanmelden met X
                        </b-button>
                    </b-col>
                    <b-col class="mb-2" v-if="hasMethod('spotify')">
                        <b-button variant="spotify" size="sm" block>
                            <font-awesome-icon icon="fa-brands fa-spotify" class="mr-2" />
                            Aanmelden met Spotify
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </template>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default ({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        props: {
            redirect_to: {
                type: [String, Boolean],
                default: '/account'
            },
            verify_to: {
                type: String,
                default: '/account/login'
            },
            title: {
                type: Boolean,
                default: true
            },
            signin_methods: {
                type: Array,
                default: () => [
                    'facebook',
                    'google',
                    'linkedin',
                    'apple',
                ],
            }
        },
        data() {
            return {
                isSubmited: false,
                isWaiting: false,
                isSuccess: false,
                error: '',
                form: {
                    email: '',
                    goto: '',
                }
            }
        },
        computed: {
            hasMethods() {
                return (this.signin_methods.length ? true : false)
            },
        },
        methods: {
            hasMethod: function(method) {
                if (this.signin_methods.includes(method)) {
                    return true;
                }
                return false;
            },
            async login() {
                this.error = null;
                this.isSubmited = true;

                let check = await this.$refs['form1'].validate();

                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
        
                if (!check) {
                    this.isSubmited = false;
                    return;
                }

                this.form.goto = window.location.host + this.verify_to;
        
                this.$axios.post("https://api.tixgo.nl/customer/login", this.form)
                    .then( response => {
                    if (response.data.status == 'success') {
                        this.isWaiting = true;
                    } else {
                        this.error = 'Er is geen account gevonden met het door u opgegeven e-mailadres.'
                    }

                    this.isSubmited = false;
                });
            },
            verify: function() {
                this.$axios.post("https://api.tixgo.nl/customer/verifylogin", this.form)
                    .then(response => {
                    if (response.data.status == 'success') {
                        this.$cookies.set('token', response.data.session.token);
                        localStorage.setItem('type', response.data.session.type);

                        this.$axios.defaults.headers.common = {
                            'Authorization': `Bearer ${this.$cookies.get('token')}`
                        };

                        this.isSuccess = true;

                        this.$emit('success', true);
                        
                        if (this.redirect_to) {
                            this.$router.push(this.redirect_to);
                        }
                    } else {
                        this.error = 'Je verificatie link is verlopen of ongeldig.'
                    }
                });
            }
        },
        created() {
            if (this.$route.query.token && this.$route.query.email) {
                this.isWaiting = true;
                this.form.token = this.$route.query.token;
                this.form.email = this.$route.query.email;
        
                this.verify();
            }
        }
    });
</script>