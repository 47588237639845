<template>
  <div>
    <template v-if="!title">
      <b-jumbotron fluid container-fluid header-level="4" class="hero mb-0">
          <template #header>
            <b-skeleton height="50px" class="mb-4" animation="wave" />
          </template>
          <template v-if="lead !== false" #lead>
            <b-skeleton animation="wave" />
          </template>
          <p v-if="sublead !== false" class="sublead">
            <b-skeleton animation="wave" />
          </p>
          <b-skeleton class="mt-1" v-if="button !== false" animation="wave" type="button" />
      </b-jumbotron>
    </template>
    <template v-else>
      <b-jumbotron fluid container-fluid header-level="4" class="hero mb-0" :style="{ backgroundImage: 'url(' + (header ? header : require('@/assets/header_fallback.png')) + ')' }">
          <template #header>
            {{ title }}
          </template>
          <template v-if="lead" #lead>
            {{ lead }}
          </template>
          <p v-if="sublead" class="sublead">
            {{ sublead }}
          </p>
          <b-button class="mt-1" v-if="button && link" size="lg" variant="primary" :to="link">
            {{ button }}
          </b-button>
      </b-jumbotron>
    </template>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
        title: [String, Boolean, Number],
        lead: [String, Boolean, Number],
        sublead: [String, Boolean, Number],
        button: [String, Boolean, Number],
        link: [String, Boolean, Number],
        header: [String, Boolean, Number],
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
    created() {}
  }
</script>

<style scoped>
  .hero > *{
      z-index:1;
      position:relative;
  }
  .hero:after{
      content:"";
      background-color:rgba(0,0,0,0.3);
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index:0;
  }
  .hero{
      position:relative;
      background-color:rgba(0,0,0,.12);
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      color:#fff;
      max-height:500px;
      min-height:325px;
      padding-top:150px;
      padding-bottom:50px;
  }
  .hero h1{
      font-size:2.5rem;
      line-height:2.5rem;
  }

  .hero .lead{
      font-size:1.3rem;
  }

  @media screen and (min-width:768px) {
      .hero{
          padding-top:180px;
          padding-bottom:80px;
      }
      .hero h1{
          font-size:3rem;
          line-height:3rem;
      }
  }
</style>