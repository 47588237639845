<template>
    <div>
        <template v-if="isLoggedIn() && Object.keys( customer ).length">
            <b-alert class="border" variant="light" show align-v="center">
                <span class="mr-2">
                    <font-awesome-icon icon="info-circle" class="mr-1"/>
                    Je bent ingelogd als {{ customer.firstname }} {{ customer.lastname }}
                </span>
                <b-button @click="switchAccount()" size="xs" variant="outline-secondary mt-2 mt-md-0 align-baseline">
                    Account Wisselen
                </b-button>
            </b-alert>
        </template>
        <template v-else>
            <b-alert class="border" variant="light" show align-v="center">
                <span class="mr-2">
                    <font-awesome-icon icon="info-circle" class="mr-1"/>
                    Al een Tixgo-account?
                </span>
                <b-button @click="$bvModal.show('login-modal')" size="xs" variant="outline-secondary mt-2 mt-md-0 align-baseline">
                    Inloggen
                </b-button>
            </b-alert>
        </template>
        <b-row>
            <b-col md="6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Voornaam</label>
                        <b-form-input v-model="form.customer.firstname" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-col>
            <b-col md="6">
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Achternaam</label>
                        <b-form-input v-model="form.customer.lastname" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-alert class="border" variant="info" show>
            <font-awesome-icon icon="info-circle" class="mr-1"/>
            Voer een geldig e-mailadres in. Tickets worden naar dit e-mailadres verzonden.
        </b-alert>
        <b-row>
            <b-col md="6">
                <ValidationProvider vid="email" rules="required|email" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">E-mailadres</label>
                        <b-form-input v-model="form.customer.email" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-col>
            <b-col md="6">
                <ValidationProvider rules="required|email|confirmed:email" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">E-mailadres (herhalen)</label>
                        <b-form-input v-model="form.customer.email_repeat" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-col>
        </b-row>
        <ValidationProvider rules="required|phone" v-slot="{ errors }">
            <div class="mb-3">
                <label class="required">Telefoonnummer</label>
                <b-form-phone v-model="form.customer.phone" />
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
                <label class="required">Geboortedatum</label>
                <b-form-input v-model="form.customer.birthdate" type="date" max="9999-12-31" />
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
                <label class="required">Geslacht</label>
                <b-form-select v-model="form.customer.gender" :options="genderSelect" />
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
        </ValidationProvider>
        <b-row>
            <b-col sm="6" md="5">
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Adres</label>
                        <b-form-input v-model="form.customer.address" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-col>
            <b-col sm="6" md="3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Postcode</label>
                        <b-form-input v-model="form.customer.zipcode" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-col>
            <b-col sm="12" md="4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Woonplaats</label>
                        <b-form-input v-model="form.customer.city" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </b-col>
        </b-row>

        <b-alert v-if="isLoggedIn()" class="border" variant="light" show>
            <ValidationProvider v-slot="{ errors }">
                <div>
                <b-form-checkbox v-model="form.is_update_customer">
                    Ja, ik wil mijn profiel updaten met de bovenstaande gegevens.
                </b-form-checkbox>
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
        </b-alert>
        <b-modal
            id="login-modal"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            >
            <div class="p-4">
                <CheckoutLoginForm 
                :redirect_to="($route.params.host ? '/' + $route.params.host : '') + '/event/' + $route.params.event + '/' + $route.params.slot + '/checkout'"
                :verify_to="($route.params.host ? '/' + $route.params.host : '') + '/event/' + $route.params.event + '/' + $route.params.slot + '/checkout'"
                @success="refreshData()"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import BFormPhone from '@/shared/components/BootstrapPhoneInput'
  import CheckoutLoginForm from '@/shared/components/CheckoutLoginForm'

  export default {
    components: {
      ValidationProvider,
      BFormPhone,
      CheckoutLoginForm,
    },
    props: {
        form: {
            type: [Object, String],
            default: null,
        },
    },
    data() {
      return {
        customer: {},
        genderSelect: [
          { text: 'Maak een keuze', value: null },
          { text: 'Man', value: 'male' },
          { text: 'Vrouw', value: 'female' },
          { text: 'Anders', value: 'other' },
        ],
      }
    },
    computed: {},
    methods: {
        refreshData: function() {
            this.fetchCustomer();
        },
        isLoggedIn: function() {
            if (this.$cookies.get('token')) {
                return true;
            }
            return false;
        },
        switchAccount: function() {
            this.$cookies.remove('token');
            localStorage.removeItem('type');

            this.customer = {};
            this.form.customer = {};
            this.$bvModal.show('login-modal');
        },
        fetchCustomer: function() {
            if (this.isLoggedIn()) {
                this.$axios.get('https://api.tixgo.nl/customer')
                .then( response => {
                    if (response.data.status == 'success') {
                        this.customer = response.data.customer;
                        this.form.customer = response.data.customer;
                        this.form.customer.email_repeat = this.form.customer.email;
                    } else {
                        this.$cookies.remove('token');
                        localStorage.removeItem('type');
                        
                        this.$bvModal.show('login-modal');
                    }
                }).catch((reason) => {
                    reason;
                });
            }
        },
    },
    mounted() {
        if (!this.isLoggedIn()) {
            this.$bvModal.show('login-modal');
        }
    },
  }
</script>